import { React } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Frontpage from "./components/Frontpage";
import{ init } from '@emailjs/browser';
init("user_bsUhyBlEcvLPv87eulM1l");

const App = () => {
  return (
    <div style={{ background: "black", color: "#696969" }}>
      <Header />
      <Frontpage />
      <Footer />
    </div>

  );
};

export default App;
