import { useState, React } from "react";
import emailjs from "@emailjs/browser";
import { Row, Col, Form } from "react-bootstrap";
import { Button, ComponentContainer, ContactBg } from "../styled.js";

const Contact = () => {
  const [error, setError] = useState();

  const intialState = {
    name: "",
    email: "",
    message: "",
  };
  const [state, setState] = useState(intialState);

  const handleInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    setError("");
    const { name, email, message } = state;
    const templateParams = {
      name: name,
      email: email,
      message: "Message to Alinen: " + message,
    };
    emailjs
      .send(
        "gmail",
        "tonitest",
        templateParams,
        process.env.REACT_APP_EMAILJS_USER
      )
      .then(
        function (response) {
          setError("Your message was sent, thank you!");
          setState(intialState);
        },
        function (error) {
          setError(
            "An error occurred. Please send email to alinenofficial@gmail.com."
          );
        }
      );
  };
  return (
    <div id="contact">
      <ContactBg>
        <ComponentContainer>
          <br />
          <br />
          <h2>Contact</h2>
          <table>
            <tbody>
              <tr>
                <td>Email:</td>
                <td>
                  <a href="mailto:alinenofficial@gmail.com">
                    alinenofficial@gmail.com
                  </a>
                </td>
              </tr>
              <tr>
                <td>Tel:</td>
                <td>
                  <a href="tel:+358503225108">+358 50 322 5108</a>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <h4>Send message</h4>
          <Form className="search-form" onSubmit={sendMessage}>
            {error && <p className="errorMsg">{error}</p>}
            <Row>
              <Col>
                <Form.Group controlId="name">
                  {/* <FormStyle> */}
                  <Form.Control
                    type="text"
                    name="name"
                    value={state.name || ""}
                    placeholder="Name"
                    onChange={handleInputChange}
                  />
                  {/* </FormStyle> */}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="name">
                  <Form.Control
                    type="text"
                    name="email"
                    value={state.email || ""}
                    placeholder="Email"
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="name">
                  <Form.Control
                    as="textarea"
                    rows={10}
                    name="message"
                    value={state.message || ""}
                    placeholder="Message"
                    onChange={handleInputChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button variant="info" type="submit">
              Send
            </Button>
            <br />
            <br />
            <br />
          </Form>
        </ComponentContainer>
      </ContactBg>
    </div>
  );
};

export default Contact;
