import React from "react";
import { ComponentContainer } from "../styled.js";
import { ReleasesBg } from "../styled.js";

const Releases = () => {
  return (
    <div id="music">
      <ReleasesBg>
        <ComponentContainer>
          <br />
          <br />
          <h2>Music</h2>
          <div className="video-container">
            <div className="video-row">
              <iframe
                className="video-frame"
                src="https://www.youtube.com/embed/fylvULQRm4Y"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <br />
              <iframe
                className="video-frame"
                src="https://www.youtube.com/embed/hTpEfZku3AU"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-row">
              <iframe
                className="video-frame"
                src="https://www.youtube.com/embed/KMyL1cSb4Gc"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <br />
              <iframe
                className="video-frame"
                src="https://youtube.com/embed/8tJc9_GprBU"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <br />
              <iframe
                className="video-frame"
                src="https://www.youtube.com/embed/2UMA4nzpmR0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>

          </div>
        </ComponentContainer>
      </ReleasesBg>
    </div>
  );
};

export default Releases;
