import React from "react";
import { ComponentContainer } from "../styled.js";

const a = (function () {
  var i,
    e,
    d = document,
    s = "script";
  i = d.createElement("script");
  i.async = 1;
  i.charset = "UTF-8";
  i.src =
    "https://cdn.curator.io/published/687894ed-6f76-445b-9873-4414d2d2167a.js";
  e = d.getElementsByTagName(s)[0];
  e.parentNode.insertBefore(i, e);
})();

const Gallery = () => {
  return (
    <div id="pix">
      <ComponentContainer>
        <br />
        <br />
        <h2>Pix</h2>
        <div id="curator-feed-default-feed-layout" />
        <script type="text/javascript">{a}</script>
      </ComponentContainer>
    </div>
  );
};

export default Gallery;
