import React from "react";
import Band from "./Band";
import Releases from "./Releases";
import Concerts from "./Concerts";
import Gallery from "./Gallery";
import Contact from "./Contact";

const Frontpage = () => {
  return (
    <div>
      <br />
      <Band />
      <Releases />
      <Concerts />
      <Gallery />
      <Contact />
    </div>
  );
};

export default Frontpage;
