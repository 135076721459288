import React from "react";
import FbLogo from "../images/facebook.png";
import SpotifyLogo from "../images/spotify.png";
import EmailLogo from "../images/email.png";
import InstagramLogo from "../images/instagram.png";
import { FooterStyle } from "../styled";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <FooterStyle>
      <div className="footer-line"></div>
      <a href="https://facebook.com/alinenband">
        <img className="some-icon" alt="Facebook Alinen" src={FbLogo} />{" "}
      </a>
      <a href="https://open.spotify.com/artist/4U47OP3gdgWtX8NeZAPAVn?si=3t6G9hZ-S-WAwiPy5cyP4Q">
        <img
          className="some-icon"
          alt="Spotify Alinen"
          src={SpotifyLogo}
        />{" "}
      </a>
      <a href="https://www.instagram.com/alinenband/">
        <img
          className="some-icon"
          alt="Instagram Alinen"
          src={InstagramLogo}
        />{" "}
      </a>
      <a href="mailto:alinenofficial@gmail.com">
        <img
          className="some-icon"
          alt="Email Alinen"
          src={EmailLogo}
        />{" "}
      </a>
      <div style={{ marginBottom: "5px" }} />
    </FooterStyle>
  );
};

export default Footer;
